export const environment = {
  name: 'production',
  production: true,
  apiUrl: 'https://api.petmore.com.br/api/v1',
  googleApiToken: 'AIzaSyCCRmAReODgzMdpftfpXA42oSsofwuemRw',
  privacyPolicyUrl: 'https://api.petmore.com.br/privacy_policy/',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.petmore.app',
  appStoreUrl: 'https://apps.apple.com/br/app/petmore/id1540622218',
  website: 'https://www.petmore.com.br',
};
